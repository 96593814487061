"use client";

import ErrorPage from "@/components/layout/ErrorPage";
import errorTracker from "@/lib/errorTracker";
import Image from "next/image";
import { useEffect } from "react";

export default function Error({ error }: { error: Error & { digest?: string } }) {
  useEffect(() => {
    errorTracker.sendError(error);
  }, [error]);

  return (
    <ErrorPage
      title="Something went wrong"
      message="An error occurred. Please try again later or contact support if the problem persists."
      icon={<Image src="/images/bad-link-circle.svg" alt="Error" width={200} height={200} />}
    />
  );
}
